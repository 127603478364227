.mat-column-actions {
	width: 4rem;
}
.mat-column-id {
	width: 5rem;
}

.table-default {
	border-collapse: collapse;
	th,
	td {
		padding: 0.5rem;
		border: 1px solid #ccc;
	}
}

.table-responsive {
	overflow-x: auto;
}
