html,
body {
	padding: 0;
	margin: 0;
	height: 100%;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}
::-webkit-scrollbar {
	width: 0.5rem;
}
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px #ccc;
}
::-webkit-scrollbar-thumb {
	background-color: #3f51b5;
}
* {
	box-sizing: border-box;
}
figure {
	line-height: 0;
	margin: 0;
	padding: 0;
}
img {
	width: auto;
	height: auto;
	max-width: 100%;
}
figcaption {
	line-height: normal;
}
small {
	font-size: 90%;
}
/*
ul {
    list-style: none;
}

li {
    line-height: 2.5
}

h1 {
    font-size: 3rem;
    font-weight: 400;
    @media (max-width:1280px) {
        font-size: 2.4rem;
    }
    @media (max-width:1024px) {
        font-size: 2.2rem;
    }
    @media (max-width:420px) {
        font-size: 1.8rem;
    }
}
h2 {
    font-size: 2rem;
    font-weight: 500;
    @media (max-width:1280px) {
        font-size: 1.9rem;
    }
    @media (max-width:1024px) {
        font-size: 1.6rem;
    }
}
h3 {
    font-weight: 500;
    font-size: 1.5rem;
    @media (max-width:1280px) {
        font-size: 1.3rem;
    }
    @media (max-width:1024px) {
        font-size: 1.1rem;
    }
}
h4 {
    font-weight: 500;
    font-size: 1.3rem;
    @media (max-width:1280px) {
        font-size: 1.2rem;
    }
    @media (max-width:1024px) {
        font-size: 1.1rem;
    }
}
p {
    font-size: 1rem;
    line-height: 1.7;
    margin-bottom: .6rem;
}*/
