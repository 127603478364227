.mat-accordion-menu-principal {
	.mat-expansion-panel-body {
		padding: 0;
	}
}

.mat-tab-form {
	.mat-mdc-tab-body-content {
		overflow-x: hidden;
	}
}

.mat-icon-table {
	margin: 0 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
	background-color: #fff;
}

.mat-mdc-card-title-group {
	padding: 1rem;
}
.mdc-card__actions {
	padding: 1rem !important;
}
