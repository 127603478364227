.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	width: 100%;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 576px) {
	.container-sm,
	.container {
		max-width: 540px;
	}
}
@media (min-width: 768px) {
	.container-md,
	.container-sm,
	.container {
		max-width: 720px;
	}
}
@media (min-width: 992px) {
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 960px;
	}
}
@media (min-width: 1200px) {
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 1140px;
	}
}
@media (min-width: 1400px) {
	.container-xxl,
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: 1320px;
	}
}
.row {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(-1 * var(--bs-gutter-y));
	margin-right: calc(-0.5 * var(--bs-gutter-x));
	margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
	box-sizing: border-box;
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-top: var(--bs-gutter-y);
}

.col {
	flex: 1 0 0%;
}

.row-cols-auto > * {
	flex: 0 0 auto;
	width: auto;
}

.row-cols-1 > * {
	flex: 0 0 auto;
	width: 100%;
}

.row-cols-2 > * {
	flex: 0 0 auto;
	width: 50%;
}

.row-cols-3 > * {
	flex: 0 0 auto;
	width: 33.3333333333%;
}

.row-cols-4 > * {
	flex: 0 0 auto;
	width: 25%;
}

.row-cols-5 > * {
	flex: 0 0 auto;
	width: 20%;
}

.row-cols-6 > * {
	flex: 0 0 auto;
	width: 16.6666666667%;
}

.col-auto {
	flex: 0 0 auto;
	width: auto;
}

.col-1 {
	flex: 0 0 auto;
	width: 8.33333333%;
}

.col-2 {
	flex: 0 0 auto;
	width: 16.66666667%;
}

.col-3 {
	flex: 0 0 auto;
	width: 25%;
}

.col-4 {
	flex: 0 0 auto;
	width: 33.33333333%;
}

.col-5 {
	flex: 0 0 auto;
	width: 41.66666667%;
}

.col-6 {
	flex: 0 0 auto;
	width: 50%;
}

.col-7 {
	flex: 0 0 auto;
	width: 58.33333333%;
}

.col-8 {
	flex: 0 0 auto;
	width: 66.66666667%;
}

.col-9 {
	flex: 0 0 auto;
	width: 75%;
}

.col-10 {
	flex: 0 0 auto;
	width: 83.33333333%;
}

.col-11 {
	flex: 0 0 auto;
	width: 91.66666667%;
}

.col-12 {
	flex: 0 0 auto;
	width: 100%;
}

.offset-1 {
	margin-left: 8.33333333%;
}

.offset-2 {
	margin-left: 16.66666667%;
}

.offset-3 {
	margin-left: 25%;
}

.offset-4 {
	margin-left: 33.33333333%;
}

.offset-5 {
	margin-left: 41.66666667%;
}

.offset-6 {
	margin-left: 50%;
}

.offset-7 {
	margin-left: 58.33333333%;
}

.offset-8 {
	margin-left: 66.66666667%;
}

.offset-9 {
	margin-left: 75%;
}

.offset-10 {
	margin-left: 83.33333333%;
}

.offset-11 {
	margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
	--bs-gutter-x: 0;
}

.g-0,
.gy-0 {
	--bs-gutter-y: 0;
}

.g-1,
.gx-1 {
	--bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
	--bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
	--bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
	--bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
	--bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
	--bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
	--bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
	--bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
	--bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
	--bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
	.col-sm {
		flex: 1 0 0%;
	}
	.row-cols-sm-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	.row-cols-sm-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	.row-cols-sm-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	.row-cols-sm-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.row-cols-sm-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	.row-cols-sm-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	.row-cols-sm-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-sm-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-sm-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}
	.col-sm-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}
	.col-sm-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-sm-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
	.col-sm-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}
	.col-sm-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-sm-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}
	.col-sm-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}
	.col-sm-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-sm-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}
	.col-sm-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}
	.col-sm-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-sm-0 {
		margin-left: 0;
	}
	.offset-sm-1 {
		margin-left: 8.33333333%;
	}
	.offset-sm-2 {
		margin-left: 16.66666667%;
	}
	.offset-sm-3 {
		margin-left: 25%;
	}
	.offset-sm-4 {
		margin-left: 33.33333333%;
	}
	.offset-sm-5 {
		margin-left: 41.66666667%;
	}
	.offset-sm-6 {
		margin-left: 50%;
	}
	.offset-sm-7 {
		margin-left: 58.33333333%;
	}
	.offset-sm-8 {
		margin-left: 66.66666667%;
	}
	.offset-sm-9 {
		margin-left: 75%;
	}
	.offset-sm-10 {
		margin-left: 83.33333333%;
	}
	.offset-sm-11 {
		margin-left: 91.66666667%;
	}
	.g-sm-0,
	.gx-sm-0 {
		--bs-gutter-x: 0;
	}
	.g-sm-0,
	.gy-sm-0 {
		--bs-gutter-y: 0;
	}
	.g-sm-1,
	.gx-sm-1 {
		--bs-gutter-x: 0.25rem;
	}
	.g-sm-1,
	.gy-sm-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-sm-2,
	.gx-sm-2 {
		--bs-gutter-x: 0.5rem;
	}
	.g-sm-2,
	.gy-sm-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-sm-3,
	.gx-sm-3 {
		--bs-gutter-x: 1rem;
	}
	.g-sm-3,
	.gy-sm-3 {
		--bs-gutter-y: 1rem;
	}
	.g-sm-4,
	.gx-sm-4 {
		--bs-gutter-x: 1.5rem;
	}
	.g-sm-4,
	.gy-sm-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-sm-5,
	.gx-sm-5 {
		--bs-gutter-x: 3rem;
	}
	.g-sm-5,
	.gy-sm-5 {
		--bs-gutter-y: 3rem;
	}
}
@media (min-width: 768px) {
	.col-md {
		flex: 1 0 0%;
	}
	.row-cols-md-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	.row-cols-md-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	.row-cols-md-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	.row-cols-md-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.row-cols-md-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	.row-cols-md-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	.row-cols-md-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-md-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-md-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}
	.col-md-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}
	.col-md-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-md-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
	.col-md-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}
	.col-md-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-md-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}
	.col-md-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}
	.col-md-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-md-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}
	.col-md-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}
	.col-md-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-md-0 {
		margin-left: 0;
	}
	.offset-md-1 {
		margin-left: 8.33333333%;
	}
	.offset-md-2 {
		margin-left: 16.66666667%;
	}
	.offset-md-3 {
		margin-left: 25%;
	}
	.offset-md-4 {
		margin-left: 33.33333333%;
	}
	.offset-md-5 {
		margin-left: 41.66666667%;
	}
	.offset-md-6 {
		margin-left: 50%;
	}
	.offset-md-7 {
		margin-left: 58.33333333%;
	}
	.offset-md-8 {
		margin-left: 66.66666667%;
	}
	.offset-md-9 {
		margin-left: 75%;
	}
	.offset-md-10 {
		margin-left: 83.33333333%;
	}
	.offset-md-11 {
		margin-left: 91.66666667%;
	}
	.g-md-0,
	.gx-md-0 {
		--bs-gutter-x: 0;
	}
	.g-md-0,
	.gy-md-0 {
		--bs-gutter-y: 0;
	}
	.g-md-1,
	.gx-md-1 {
		--bs-gutter-x: 0.25rem;
	}
	.g-md-1,
	.gy-md-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-md-2,
	.gx-md-2 {
		--bs-gutter-x: 0.5rem;
	}
	.g-md-2,
	.gy-md-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-md-3,
	.gx-md-3 {
		--bs-gutter-x: 1rem;
	}
	.g-md-3,
	.gy-md-3 {
		--bs-gutter-y: 1rem;
	}
	.g-md-4,
	.gx-md-4 {
		--bs-gutter-x: 1.5rem;
	}
	.g-md-4,
	.gy-md-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-md-5,
	.gx-md-5 {
		--bs-gutter-x: 3rem;
	}
	.g-md-5,
	.gy-md-5 {
		--bs-gutter-y: 3rem;
	}
}
@media (min-width: 992px) {
	.col-lg {
		flex: 1 0 0%;
	}
	.row-cols-lg-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	.row-cols-lg-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	.row-cols-lg-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	.row-cols-lg-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.row-cols-lg-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	.row-cols-lg-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	.row-cols-lg-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-lg-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-lg-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}
	.col-lg-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}
	.col-lg-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-lg-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
	.col-lg-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}
	.col-lg-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-lg-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}
	.col-lg-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}
	.col-lg-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-lg-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}
	.col-lg-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}
	.col-lg-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-lg-0 {
		margin-left: 0;
	}
	.offset-lg-1 {
		margin-left: 8.33333333%;
	}
	.offset-lg-2 {
		margin-left: 16.66666667%;
	}
	.offset-lg-3 {
		margin-left: 25%;
	}
	.offset-lg-4 {
		margin-left: 33.33333333%;
	}
	.offset-lg-5 {
		margin-left: 41.66666667%;
	}
	.offset-lg-6 {
		margin-left: 50%;
	}
	.offset-lg-7 {
		margin-left: 58.33333333%;
	}
	.offset-lg-8 {
		margin-left: 66.66666667%;
	}
	.offset-lg-9 {
		margin-left: 75%;
	}
	.offset-lg-10 {
		margin-left: 83.33333333%;
	}
	.offset-lg-11 {
		margin-left: 91.66666667%;
	}
	.g-lg-0,
	.gx-lg-0 {
		--bs-gutter-x: 0;
	}
	.g-lg-0,
	.gy-lg-0 {
		--bs-gutter-y: 0;
	}
	.g-lg-1,
	.gx-lg-1 {
		--bs-gutter-x: 0.25rem;
	}
	.g-lg-1,
	.gy-lg-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-lg-2,
	.gx-lg-2 {
		--bs-gutter-x: 0.5rem;
	}
	.g-lg-2,
	.gy-lg-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-lg-3,
	.gx-lg-3 {
		--bs-gutter-x: 1rem;
	}
	.g-lg-3,
	.gy-lg-3 {
		--bs-gutter-y: 1rem;
	}
	.g-lg-4,
	.gx-lg-4 {
		--bs-gutter-x: 1.5rem;
	}
	.g-lg-4,
	.gy-lg-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-lg-5,
	.gx-lg-5 {
		--bs-gutter-x: 3rem;
	}
	.g-lg-5,
	.gy-lg-5 {
		--bs-gutter-y: 3rem;
	}
}
@media (min-width: 1200px) {
	.col-xl {
		flex: 1 0 0%;
	}
	.row-cols-xl-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	.row-cols-xl-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	.row-cols-xl-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	.row-cols-xl-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.row-cols-xl-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	.row-cols-xl-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	.row-cols-xl-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-xl-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-xl-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}
	.col-xl-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}
	.col-xl-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-xl-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
	.col-xl-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}
	.col-xl-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-xl-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}
	.col-xl-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}
	.col-xl-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-xl-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}
	.col-xl-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}
	.col-xl-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-xl-0 {
		margin-left: 0;
	}
	.offset-xl-1 {
		margin-left: 8.33333333%;
	}
	.offset-xl-2 {
		margin-left: 16.66666667%;
	}
	.offset-xl-3 {
		margin-left: 25%;
	}
	.offset-xl-4 {
		margin-left: 33.33333333%;
	}
	.offset-xl-5 {
		margin-left: 41.66666667%;
	}
	.offset-xl-6 {
		margin-left: 50%;
	}
	.offset-xl-7 {
		margin-left: 58.33333333%;
	}
	.offset-xl-8 {
		margin-left: 66.66666667%;
	}
	.offset-xl-9 {
		margin-left: 75%;
	}
	.offset-xl-10 {
		margin-left: 83.33333333%;
	}
	.offset-xl-11 {
		margin-left: 91.66666667%;
	}
	.g-xl-0,
	.gx-xl-0 {
		--bs-gutter-x: 0;
	}
	.g-xl-0,
	.gy-xl-0 {
		--bs-gutter-y: 0;
	}
	.g-xl-1,
	.gx-xl-1 {
		--bs-gutter-x: 0.25rem;
	}
	.g-xl-1,
	.gy-xl-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-xl-2,
	.gx-xl-2 {
		--bs-gutter-x: 0.5rem;
	}
	.g-xl-2,
	.gy-xl-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-xl-3,
	.gx-xl-3 {
		--bs-gutter-x: 1rem;
	}
	.g-xl-3,
	.gy-xl-3 {
		--bs-gutter-y: 1rem;
	}
	.g-xl-4,
	.gx-xl-4 {
		--bs-gutter-x: 1.5rem;
	}
	.g-xl-4,
	.gy-xl-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-xl-5,
	.gx-xl-5 {
		--bs-gutter-x: 3rem;
	}
	.g-xl-5,
	.gy-xl-5 {
		--bs-gutter-y: 3rem;
	}
}
@media (min-width: 1400px) {
	.col-xxl {
		flex: 1 0 0%;
	}
	.row-cols-xxl-auto > * {
		flex: 0 0 auto;
		width: auto;
	}
	.row-cols-xxl-1 > * {
		flex: 0 0 auto;
		width: 100%;
	}
	.row-cols-xxl-2 > * {
		flex: 0 0 auto;
		width: 50%;
	}
	.row-cols-xxl-3 > * {
		flex: 0 0 auto;
		width: 33.3333333333%;
	}
	.row-cols-xxl-4 > * {
		flex: 0 0 auto;
		width: 25%;
	}
	.row-cols-xxl-5 > * {
		flex: 0 0 auto;
		width: 20%;
	}
	.row-cols-xxl-6 > * {
		flex: 0 0 auto;
		width: 16.6666666667%;
	}
	.col-xxl-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-xxl-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}
	.col-xxl-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}
	.col-xxl-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-xxl-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
	.col-xxl-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}
	.col-xxl-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-xxl-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}
	.col-xxl-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}
	.col-xxl-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-xxl-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}
	.col-xxl-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}
	.col-xxl-12 {
		flex: 0 0 auto;
		width: 100%;
	}
	.offset-xxl-0 {
		margin-left: 0;
	}
	.offset-xxl-1 {
		margin-left: 8.33333333%;
	}
	.offset-xxl-2 {
		margin-left: 16.66666667%;
	}
	.offset-xxl-3 {
		margin-left: 25%;
	}
	.offset-xxl-4 {
		margin-left: 33.33333333%;
	}
	.offset-xxl-5 {
		margin-left: 41.66666667%;
	}
	.offset-xxl-6 {
		margin-left: 50%;
	}
	.offset-xxl-7 {
		margin-left: 58.33333333%;
	}
	.offset-xxl-8 {
		margin-left: 66.66666667%;
	}
	.offset-xxl-9 {
		margin-left: 75%;
	}
	.offset-xxl-10 {
		margin-left: 83.33333333%;
	}
	.offset-xxl-11 {
		margin-left: 91.66666667%;
	}
	.g-xxl-0,
	.gx-xxl-0 {
		--bs-gutter-x: 0;
	}
	.g-xxl-0,
	.gy-xxl-0 {
		--bs-gutter-y: 0;
	}
	.g-xxl-1,
	.gx-xxl-1 {
		--bs-gutter-x: 0.25rem;
	}
	.g-xxl-1,
	.gy-xxl-1 {
		--bs-gutter-y: 0.25rem;
	}
	.g-xxl-2,
	.gx-xxl-2 {
		--bs-gutter-x: 0.5rem;
	}
	.g-xxl-2,
	.gy-xxl-2 {
		--bs-gutter-y: 0.5rem;
	}
	.g-xxl-3,
	.gx-xxl-3 {
		--bs-gutter-x: 1rem;
	}
	.g-xxl-3,
	.gy-xxl-3 {
		--bs-gutter-y: 1rem;
	}
	.g-xxl-4,
	.gx-xxl-4 {
		--bs-gutter-x: 1.5rem;
	}
	.g-xxl-4,
	.gy-xxl-4 {
		--bs-gutter-y: 1.5rem;
	}
	.g-xxl-5,
	.gx-xxl-5 {
		--bs-gutter-x: 3rem;
	}
	.g-xxl-5,
	.gy-xxl-5 {
		--bs-gutter-y: 3rem;
	}
}
.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-grid {
	display: grid !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex {
	display: flex !important;
}

.d-inline-flex {
	display: inline-flex !important;
}

.d-none {
	display: none !important;
}

.flex-fill {
	flex: 1 1 auto !important;
}

.flex-row {
	flex-direction: row !important;
}

.flex-column {
	flex-direction: column !important;
}

.flex-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-grow-0 {
	flex-grow: 0 !important;
}

.flex-grow-1 {
	flex-grow: 1 !important;
}

.flex-shrink-0 {
	flex-shrink: 0 !important;
}

.flex-shrink-1 {
	flex-shrink: 1 !important;
}

.flex-wrap {
	flex-wrap: wrap !important;
}

.flex-nowrap {
	flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.justify-content-evenly {
	justify-content: space-evenly !important;
}

.align-items-start {
	align-items: flex-start !important;
}

.align-items-end {
	align-items: flex-end !important;
}

.align-items-center {
	align-items: center !important;
}

.align-items-baseline {
	align-items: baseline !important;
}

.align-items-stretch {
	align-items: stretch !important;
}

.align-content-start {
	align-content: flex-start !important;
}

.align-content-end {
	align-content: flex-end !important;
}

.align-content-center {
	align-content: center !important;
}

.align-content-between {
	align-content: space-between !important;
}

.align-content-around {
	align-content: space-around !important;
}

.align-content-stretch {
	align-content: stretch !important;
}

.align-self-auto {
	align-self: auto !important;
}

.align-self-start {
	align-self: flex-start !important;
}

.align-self-end {
	align-self: flex-end !important;
}

.align-self-center {
	align-self: center !important;
}

.align-self-baseline {
	align-self: baseline !important;
}

.align-self-stretch {
	align-self: stretch !important;
}

.order-first {
	order: -1 !important;
}

.order-0 {
	order: 0 !important;
}

.order-1 {
	order: 1 !important;
}

.order-2 {
	order: 2 !important;
}

.order-3 {
	order: 3 !important;
}

.order-4 {
	order: 4 !important;
}

.order-5 {
	order: 5 !important;
}

.order-last {
	order: 6 !important;
}

.m-0 {
	margin: 0 !important;
}

.m-1 {
	margin: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.m-auto {
	margin: auto !important;
}

.mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.mx-1 {
	margin-right: 0.25rem !important;
	margin-left: 0.25rem !important;
}

.mx-2 {
	margin-right: 0.5rem !important;
	margin-left: 0.5rem !important;
}

.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}

.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}

.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}

.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}

.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}

.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}

.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}

.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-1 {
	margin-top: 0.25rem !important;
}

.mt-2 {
	margin-top: 0.5rem !important;
}

.mt-3 {
	margin-top: 1rem !important;
}

.mt-4 {
	margin-top: 1.5rem !important;
}

.mt-5 {
	margin-top: 3rem !important;
}

.mt-auto {
	margin-top: auto !important;
}

.me-0 {
	margin-right: 0 !important;
}

.me-1 {
	margin-right: 0.25rem !important;
}

.me-2 {
	margin-right: 0.5rem !important;
}

.me-3 {
	margin-right: 1rem !important;
}

.me-4 {
	margin-right: 1.5rem !important;
}

.me-5 {
	margin-right: 3rem !important;
}

.me-auto {
	margin-right: auto !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-1 {
	margin-bottom: 0.25rem !important;
}

.mb-2 {
	margin-bottom: 0.5rem !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

.mb-4 {
	margin-bottom: 1.5rem !important;
}

.mb-5 {
	margin-bottom: 3rem !important;
}

.mb-auto {
	margin-bottom: auto !important;
}

.ms-0 {
	margin-left: 0 !important;
}

.ms-1 {
	margin-left: 0.25rem !important;
}

.ms-2 {
	margin-left: 0.5rem !important;
}

.ms-3 {
	margin-left: 1rem !important;
}

.ms-4 {
	margin-left: 1.5rem !important;
}

.ms-5 {
	margin-left: 3rem !important;
}

.ms-auto {
	margin-left: auto !important;
}

.p-0 {
	padding: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.px-1 {
	padding-right: 0.25rem !important;
	padding-left: 0.25rem !important;
}

.px-2 {
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}

.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}

.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}

.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
}

.py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}

.py-3 {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}

.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}

.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.pt-1 {
	padding-top: 0.25rem !important;
}

.pt-2 {
	padding-top: 0.5rem !important;
}

.pt-3 {
	padding-top: 1rem !important;
}

.pt-4 {
	padding-top: 1.5rem !important;
}

.pt-5 {
	padding-top: 3rem !important;
}

.pe-0 {
	padding-right: 0 !important;
}

.pe-1 {
	padding-right: 0.25rem !important;
}

.pe-2 {
	padding-right: 0.5rem !important;
}

.pe-3 {
	padding-right: 1rem !important;
}

.pe-4 {
	padding-right: 1.5rem !important;
}

.pe-5 {
	padding-right: 3rem !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pb-1 {
	padding-bottom: 0.25rem !important;
}

.pb-2 {
	padding-bottom: 0.5rem !important;
}

.pb-3 {
	padding-bottom: 1rem !important;
}

.pb-4 {
	padding-bottom: 1.5rem !important;
}

.pb-5 {
	padding-bottom: 3rem !important;
}

.ps-0 {
	padding-left: 0 !important;
}

.ps-1 {
	padding-left: 0.25rem !important;
}

.ps-2 {
	padding-left: 0.5rem !important;
}

.ps-3 {
	padding-left: 1rem !important;
}

.ps-4 {
	padding-left: 1.5rem !important;
}

.ps-5 {
	padding-left: 3rem !important;
}

@media (min-width: 576px) {
	.d-sm-inline {
		display: inline !important;
	}
	.d-sm-inline-block {
		display: inline-block !important;
	}
	.d-sm-block {
		display: block !important;
	}
	.d-sm-grid {
		display: grid !important;
	}
	.d-sm-table {
		display: table !important;
	}
	.d-sm-table-row {
		display: table-row !important;
	}
	.d-sm-table-cell {
		display: table-cell !important;
	}
	.d-sm-flex {
		display: flex !important;
	}
	.d-sm-inline-flex {
		display: inline-flex !important;
	}
	.d-sm-none {
		display: none !important;
	}
	.flex-sm-fill {
		flex: 1 1 auto !important;
	}
	.flex-sm-row {
		flex-direction: row !important;
	}
	.flex-sm-column {
		flex-direction: column !important;
	}
	.flex-sm-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-sm-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-sm-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-sm-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-sm-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-sm-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-sm-wrap {
		flex-wrap: wrap !important;
	}
	.flex-sm-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-sm-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.justify-content-sm-start {
		justify-content: flex-start !important;
	}
	.justify-content-sm-end {
		justify-content: flex-end !important;
	}
	.justify-content-sm-center {
		justify-content: center !important;
	}
	.justify-content-sm-between {
		justify-content: space-between !important;
	}
	.justify-content-sm-around {
		justify-content: space-around !important;
	}
	.justify-content-sm-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-sm-start {
		align-items: flex-start !important;
	}
	.align-items-sm-end {
		align-items: flex-end !important;
	}
	.align-items-sm-center {
		align-items: center !important;
	}
	.align-items-sm-baseline {
		align-items: baseline !important;
	}
	.align-items-sm-stretch {
		align-items: stretch !important;
	}
	.align-content-sm-start {
		align-content: flex-start !important;
	}
	.align-content-sm-end {
		align-content: flex-end !important;
	}
	.align-content-sm-center {
		align-content: center !important;
	}
	.align-content-sm-between {
		align-content: space-between !important;
	}
	.align-content-sm-around {
		align-content: space-around !important;
	}
	.align-content-sm-stretch {
		align-content: stretch !important;
	}
	.align-self-sm-auto {
		align-self: auto !important;
	}
	.align-self-sm-start {
		align-self: flex-start !important;
	}
	.align-self-sm-end {
		align-self: flex-end !important;
	}
	.align-self-sm-center {
		align-self: center !important;
	}
	.align-self-sm-baseline {
		align-self: baseline !important;
	}
	.align-self-sm-stretch {
		align-self: stretch !important;
	}
	.order-sm-first {
		order: -1 !important;
	}
	.order-sm-0 {
		order: 0 !important;
	}
	.order-sm-1 {
		order: 1 !important;
	}
	.order-sm-2 {
		order: 2 !important;
	}
	.order-sm-3 {
		order: 3 !important;
	}
	.order-sm-4 {
		order: 4 !important;
	}
	.order-sm-5 {
		order: 5 !important;
	}
	.order-sm-last {
		order: 6 !important;
	}
	.m-sm-0 {
		margin: 0 !important;
	}
	.m-sm-1 {
		margin: 0.25rem !important;
	}
	.m-sm-2 {
		margin: 0.5rem !important;
	}
	.m-sm-3 {
		margin: 1rem !important;
	}
	.m-sm-4 {
		margin: 1.5rem !important;
	}
	.m-sm-5 {
		margin: 3rem !important;
	}
	.m-sm-auto {
		margin: auto !important;
	}
	.mx-sm-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-sm-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-sm-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-sm-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-sm-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-sm-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-sm-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-sm-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-sm-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-sm-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-sm-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-sm-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-sm-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-sm-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-sm-0 {
		margin-top: 0 !important;
	}
	.mt-sm-1 {
		margin-top: 0.25rem !important;
	}
	.mt-sm-2 {
		margin-top: 0.5rem !important;
	}
	.mt-sm-3 {
		margin-top: 1rem !important;
	}
	.mt-sm-4 {
		margin-top: 1.5rem !important;
	}
	.mt-sm-5 {
		margin-top: 3rem !important;
	}
	.mt-sm-auto {
		margin-top: auto !important;
	}
	.me-sm-0 {
		margin-right: 0 !important;
	}
	.me-sm-1 {
		margin-right: 0.25rem !important;
	}
	.me-sm-2 {
		margin-right: 0.5rem !important;
	}
	.me-sm-3 {
		margin-right: 1rem !important;
	}
	.me-sm-4 {
		margin-right: 1.5rem !important;
	}
	.me-sm-5 {
		margin-right: 3rem !important;
	}
	.me-sm-auto {
		margin-right: auto !important;
	}
	.mb-sm-0 {
		margin-bottom: 0 !important;
	}
	.mb-sm-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-sm-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-sm-3 {
		margin-bottom: 1rem !important;
	}
	.mb-sm-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-sm-5 {
		margin-bottom: 3rem !important;
	}
	.mb-sm-auto {
		margin-bottom: auto !important;
	}
	.ms-sm-0 {
		margin-left: 0 !important;
	}
	.ms-sm-1 {
		margin-left: 0.25rem !important;
	}
	.ms-sm-2 {
		margin-left: 0.5rem !important;
	}
	.ms-sm-3 {
		margin-left: 1rem !important;
	}
	.ms-sm-4 {
		margin-left: 1.5rem !important;
	}
	.ms-sm-5 {
		margin-left: 3rem !important;
	}
	.ms-sm-auto {
		margin-left: auto !important;
	}
	.p-sm-0 {
		padding: 0 !important;
	}
	.p-sm-1 {
		padding: 0.25rem !important;
	}
	.p-sm-2 {
		padding: 0.5rem !important;
	}
	.p-sm-3 {
		padding: 1rem !important;
	}
	.p-sm-4 {
		padding: 1.5rem !important;
	}
	.p-sm-5 {
		padding: 3rem !important;
	}
	.px-sm-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-sm-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-sm-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-sm-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-sm-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-sm-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-sm-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-sm-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-sm-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-sm-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-sm-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-sm-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-sm-0 {
		padding-top: 0 !important;
	}
	.pt-sm-1 {
		padding-top: 0.25rem !important;
	}
	.pt-sm-2 {
		padding-top: 0.5rem !important;
	}
	.pt-sm-3 {
		padding-top: 1rem !important;
	}
	.pt-sm-4 {
		padding-top: 1.5rem !important;
	}
	.pt-sm-5 {
		padding-top: 3rem !important;
	}
	.pe-sm-0 {
		padding-right: 0 !important;
	}
	.pe-sm-1 {
		padding-right: 0.25rem !important;
	}
	.pe-sm-2 {
		padding-right: 0.5rem !important;
	}
	.pe-sm-3 {
		padding-right: 1rem !important;
	}
	.pe-sm-4 {
		padding-right: 1.5rem !important;
	}
	.pe-sm-5 {
		padding-right: 3rem !important;
	}
	.pb-sm-0 {
		padding-bottom: 0 !important;
	}
	.pb-sm-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-sm-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-sm-3 {
		padding-bottom: 1rem !important;
	}
	.pb-sm-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-sm-5 {
		padding-bottom: 3rem !important;
	}
	.ps-sm-0 {
		padding-left: 0 !important;
	}
	.ps-sm-1 {
		padding-left: 0.25rem !important;
	}
	.ps-sm-2 {
		padding-left: 0.5rem !important;
	}
	.ps-sm-3 {
		padding-left: 1rem !important;
	}
	.ps-sm-4 {
		padding-left: 1.5rem !important;
	}
	.ps-sm-5 {
		padding-left: 3rem !important;
	}
}
@media (min-width: 768px) {
	.d-md-inline {
		display: inline !important;
	}
	.d-md-inline-block {
		display: inline-block !important;
	}
	.d-md-block {
		display: block !important;
	}
	.d-md-grid {
		display: grid !important;
	}
	.d-md-table {
		display: table !important;
	}
	.d-md-table-row {
		display: table-row !important;
	}
	.d-md-table-cell {
		display: table-cell !important;
	}
	.d-md-flex {
		display: flex !important;
	}
	.d-md-inline-flex {
		display: inline-flex !important;
	}
	.d-md-none {
		display: none !important;
	}
	.flex-md-fill {
		flex: 1 1 auto !important;
	}
	.flex-md-row {
		flex-direction: row !important;
	}
	.flex-md-column {
		flex-direction: column !important;
	}
	.flex-md-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-md-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-md-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-md-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-md-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-md-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-md-wrap {
		flex-wrap: wrap !important;
	}
	.flex-md-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-md-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.justify-content-md-start {
		justify-content: flex-start !important;
	}
	.justify-content-md-end {
		justify-content: flex-end !important;
	}
	.justify-content-md-center {
		justify-content: center !important;
	}
	.justify-content-md-between {
		justify-content: space-between !important;
	}
	.justify-content-md-around {
		justify-content: space-around !important;
	}
	.justify-content-md-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-md-start {
		align-items: flex-start !important;
	}
	.align-items-md-end {
		align-items: flex-end !important;
	}
	.align-items-md-center {
		align-items: center !important;
	}
	.align-items-md-baseline {
		align-items: baseline !important;
	}
	.align-items-md-stretch {
		align-items: stretch !important;
	}
	.align-content-md-start {
		align-content: flex-start !important;
	}
	.align-content-md-end {
		align-content: flex-end !important;
	}
	.align-content-md-center {
		align-content: center !important;
	}
	.align-content-md-between {
		align-content: space-between !important;
	}
	.align-content-md-around {
		align-content: space-around !important;
	}
	.align-content-md-stretch {
		align-content: stretch !important;
	}
	.align-self-md-auto {
		align-self: auto !important;
	}
	.align-self-md-start {
		align-self: flex-start !important;
	}
	.align-self-md-end {
		align-self: flex-end !important;
	}
	.align-self-md-center {
		align-self: center !important;
	}
	.align-self-md-baseline {
		align-self: baseline !important;
	}
	.align-self-md-stretch {
		align-self: stretch !important;
	}
	.order-md-first {
		order: -1 !important;
	}
	.order-md-0 {
		order: 0 !important;
	}
	.order-md-1 {
		order: 1 !important;
	}
	.order-md-2 {
		order: 2 !important;
	}
	.order-md-3 {
		order: 3 !important;
	}
	.order-md-4 {
		order: 4 !important;
	}
	.order-md-5 {
		order: 5 !important;
	}
	.order-md-last {
		order: 6 !important;
	}
	.m-md-0 {
		margin: 0 !important;
	}
	.m-md-1 {
		margin: 0.25rem !important;
	}
	.m-md-2 {
		margin: 0.5rem !important;
	}
	.m-md-3 {
		margin: 1rem !important;
	}
	.m-md-4 {
		margin: 1.5rem !important;
	}
	.m-md-5 {
		margin: 3rem !important;
	}
	.m-md-auto {
		margin: auto !important;
	}
	.mx-md-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-md-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-md-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-md-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-md-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-md-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-md-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-md-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-md-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-md-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-md-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-md-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-md-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-md-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-md-0 {
		margin-top: 0 !important;
	}
	.mt-md-1 {
		margin-top: 0.25rem !important;
	}
	.mt-md-2 {
		margin-top: 0.5rem !important;
	}
	.mt-md-3 {
		margin-top: 1rem !important;
	}
	.mt-md-4 {
		margin-top: 1.5rem !important;
	}
	.mt-md-5 {
		margin-top: 3rem !important;
	}
	.mt-md-auto {
		margin-top: auto !important;
	}
	.me-md-0 {
		margin-right: 0 !important;
	}
	.me-md-1 {
		margin-right: 0.25rem !important;
	}
	.me-md-2 {
		margin-right: 0.5rem !important;
	}
	.me-md-3 {
		margin-right: 1rem !important;
	}
	.me-md-4 {
		margin-right: 1.5rem !important;
	}
	.me-md-5 {
		margin-right: 3rem !important;
	}
	.me-md-auto {
		margin-right: auto !important;
	}
	.mb-md-0 {
		margin-bottom: 0 !important;
	}
	.mb-md-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-md-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-md-3 {
		margin-bottom: 1rem !important;
	}
	.mb-md-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-md-5 {
		margin-bottom: 3rem !important;
	}
	.mb-md-auto {
		margin-bottom: auto !important;
	}
	.ms-md-0 {
		margin-left: 0 !important;
	}
	.ms-md-1 {
		margin-left: 0.25rem !important;
	}
	.ms-md-2 {
		margin-left: 0.5rem !important;
	}
	.ms-md-3 {
		margin-left: 1rem !important;
	}
	.ms-md-4 {
		margin-left: 1.5rem !important;
	}
	.ms-md-5 {
		margin-left: 3rem !important;
	}
	.ms-md-auto {
		margin-left: auto !important;
	}
	.p-md-0 {
		padding: 0 !important;
	}
	.p-md-1 {
		padding: 0.25rem !important;
	}
	.p-md-2 {
		padding: 0.5rem !important;
	}
	.p-md-3 {
		padding: 1rem !important;
	}
	.p-md-4 {
		padding: 1.5rem !important;
	}
	.p-md-5 {
		padding: 3rem !important;
	}
	.px-md-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-md-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-md-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-md-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-md-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-md-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-md-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-md-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-md-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-md-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-md-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-md-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-md-0 {
		padding-top: 0 !important;
	}
	.pt-md-1 {
		padding-top: 0.25rem !important;
	}
	.pt-md-2 {
		padding-top: 0.5rem !important;
	}
	.pt-md-3 {
		padding-top: 1rem !important;
	}
	.pt-md-4 {
		padding-top: 1.5rem !important;
	}
	.pt-md-5 {
		padding-top: 3rem !important;
	}
	.pe-md-0 {
		padding-right: 0 !important;
	}
	.pe-md-1 {
		padding-right: 0.25rem !important;
	}
	.pe-md-2 {
		padding-right: 0.5rem !important;
	}
	.pe-md-3 {
		padding-right: 1rem !important;
	}
	.pe-md-4 {
		padding-right: 1.5rem !important;
	}
	.pe-md-5 {
		padding-right: 3rem !important;
	}
	.pb-md-0 {
		padding-bottom: 0 !important;
	}
	.pb-md-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-md-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-md-3 {
		padding-bottom: 1rem !important;
	}
	.pb-md-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-md-5 {
		padding-bottom: 3rem !important;
	}
	.ps-md-0 {
		padding-left: 0 !important;
	}
	.ps-md-1 {
		padding-left: 0.25rem !important;
	}
	.ps-md-2 {
		padding-left: 0.5rem !important;
	}
	.ps-md-3 {
		padding-left: 1rem !important;
	}
	.ps-md-4 {
		padding-left: 1.5rem !important;
	}
	.ps-md-5 {
		padding-left: 3rem !important;
	}
}
@media (min-width: 992px) {
	.d-lg-inline {
		display: inline !important;
	}
	.d-lg-inline-block {
		display: inline-block !important;
	}
	.d-lg-block {
		display: block !important;
	}
	.d-lg-grid {
		display: grid !important;
	}
	.d-lg-table {
		display: table !important;
	}
	.d-lg-table-row {
		display: table-row !important;
	}
	.d-lg-table-cell {
		display: table-cell !important;
	}
	.d-lg-flex {
		display: flex !important;
	}
	.d-lg-inline-flex {
		display: inline-flex !important;
	}
	.d-lg-none {
		display: none !important;
	}
	.flex-lg-fill {
		flex: 1 1 auto !important;
	}
	.flex-lg-row {
		flex-direction: row !important;
	}
	.flex-lg-column {
		flex-direction: column !important;
	}
	.flex-lg-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-lg-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-lg-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-lg-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-lg-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-lg-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-lg-wrap {
		flex-wrap: wrap !important;
	}
	.flex-lg-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-lg-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.justify-content-lg-start {
		justify-content: flex-start !important;
	}
	.justify-content-lg-end {
		justify-content: flex-end !important;
	}
	.justify-content-lg-center {
		justify-content: center !important;
	}
	.justify-content-lg-between {
		justify-content: space-between !important;
	}
	.justify-content-lg-around {
		justify-content: space-around !important;
	}
	.justify-content-lg-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-lg-start {
		align-items: flex-start !important;
	}
	.align-items-lg-end {
		align-items: flex-end !important;
	}
	.align-items-lg-center {
		align-items: center !important;
	}
	.align-items-lg-baseline {
		align-items: baseline !important;
	}
	.align-items-lg-stretch {
		align-items: stretch !important;
	}
	.align-content-lg-start {
		align-content: flex-start !important;
	}
	.align-content-lg-end {
		align-content: flex-end !important;
	}
	.align-content-lg-center {
		align-content: center !important;
	}
	.align-content-lg-between {
		align-content: space-between !important;
	}
	.align-content-lg-around {
		align-content: space-around !important;
	}
	.align-content-lg-stretch {
		align-content: stretch !important;
	}
	.align-self-lg-auto {
		align-self: auto !important;
	}
	.align-self-lg-start {
		align-self: flex-start !important;
	}
	.align-self-lg-end {
		align-self: flex-end !important;
	}
	.align-self-lg-center {
		align-self: center !important;
	}
	.align-self-lg-baseline {
		align-self: baseline !important;
	}
	.align-self-lg-stretch {
		align-self: stretch !important;
	}
	.order-lg-first {
		order: -1 !important;
	}
	.order-lg-0 {
		order: 0 !important;
	}
	.order-lg-1 {
		order: 1 !important;
	}
	.order-lg-2 {
		order: 2 !important;
	}
	.order-lg-3 {
		order: 3 !important;
	}
	.order-lg-4 {
		order: 4 !important;
	}
	.order-lg-5 {
		order: 5 !important;
	}
	.order-lg-last {
		order: 6 !important;
	}
	.m-lg-0 {
		margin: 0 !important;
	}
	.m-lg-1 {
		margin: 0.25rem !important;
	}
	.m-lg-2 {
		margin: 0.5rem !important;
	}
	.m-lg-3 {
		margin: 1rem !important;
	}
	.m-lg-4 {
		margin: 1.5rem !important;
	}
	.m-lg-5 {
		margin: 3rem !important;
	}
	.m-lg-auto {
		margin: auto !important;
	}
	.mx-lg-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-lg-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-lg-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-lg-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-lg-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-lg-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-lg-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-lg-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-lg-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-lg-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-lg-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-lg-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-lg-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-lg-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-lg-0 {
		margin-top: 0 !important;
	}
	.mt-lg-1 {
		margin-top: 0.25rem !important;
	}
	.mt-lg-2 {
		margin-top: 0.5rem !important;
	}
	.mt-lg-3 {
		margin-top: 1rem !important;
	}
	.mt-lg-4 {
		margin-top: 1.5rem !important;
	}
	.mt-lg-5 {
		margin-top: 3rem !important;
	}
	.mt-lg-auto {
		margin-top: auto !important;
	}
	.me-lg-0 {
		margin-right: 0 !important;
	}
	.me-lg-1 {
		margin-right: 0.25rem !important;
	}
	.me-lg-2 {
		margin-right: 0.5rem !important;
	}
	.me-lg-3 {
		margin-right: 1rem !important;
	}
	.me-lg-4 {
		margin-right: 1.5rem !important;
	}
	.me-lg-5 {
		margin-right: 3rem !important;
	}
	.me-lg-auto {
		margin-right: auto !important;
	}
	.mb-lg-0 {
		margin-bottom: 0 !important;
	}
	.mb-lg-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-lg-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-lg-3 {
		margin-bottom: 1rem !important;
	}
	.mb-lg-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-lg-5 {
		margin-bottom: 3rem !important;
	}
	.mb-lg-auto {
		margin-bottom: auto !important;
	}
	.ms-lg-0 {
		margin-left: 0 !important;
	}
	.ms-lg-1 {
		margin-left: 0.25rem !important;
	}
	.ms-lg-2 {
		margin-left: 0.5rem !important;
	}
	.ms-lg-3 {
		margin-left: 1rem !important;
	}
	.ms-lg-4 {
		margin-left: 1.5rem !important;
	}
	.ms-lg-5 {
		margin-left: 3rem !important;
	}
	.ms-lg-auto {
		margin-left: auto !important;
	}
	.p-lg-0 {
		padding: 0 !important;
	}
	.p-lg-1 {
		padding: 0.25rem !important;
	}
	.p-lg-2 {
		padding: 0.5rem !important;
	}
	.p-lg-3 {
		padding: 1rem !important;
	}
	.p-lg-4 {
		padding: 1.5rem !important;
	}
	.p-lg-5 {
		padding: 3rem !important;
	}
	.px-lg-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-lg-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-lg-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-lg-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-lg-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-lg-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-lg-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-lg-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-lg-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-lg-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-lg-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-lg-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-lg-0 {
		padding-top: 0 !important;
	}
	.pt-lg-1 {
		padding-top: 0.25rem !important;
	}
	.pt-lg-2 {
		padding-top: 0.5rem !important;
	}
	.pt-lg-3 {
		padding-top: 1rem !important;
	}
	.pt-lg-4 {
		padding-top: 1.5rem !important;
	}
	.pt-lg-5 {
		padding-top: 3rem !important;
	}
	.pe-lg-0 {
		padding-right: 0 !important;
	}
	.pe-lg-1 {
		padding-right: 0.25rem !important;
	}
	.pe-lg-2 {
		padding-right: 0.5rem !important;
	}
	.pe-lg-3 {
		padding-right: 1rem !important;
	}
	.pe-lg-4 {
		padding-right: 1.5rem !important;
	}
	.pe-lg-5 {
		padding-right: 3rem !important;
	}
	.pb-lg-0 {
		padding-bottom: 0 !important;
	}
	.pb-lg-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-lg-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-lg-3 {
		padding-bottom: 1rem !important;
	}
	.pb-lg-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-lg-5 {
		padding-bottom: 3rem !important;
	}
	.ps-lg-0 {
		padding-left: 0 !important;
	}
	.ps-lg-1 {
		padding-left: 0.25rem !important;
	}
	.ps-lg-2 {
		padding-left: 0.5rem !important;
	}
	.ps-lg-3 {
		padding-left: 1rem !important;
	}
	.ps-lg-4 {
		padding-left: 1.5rem !important;
	}
	.ps-lg-5 {
		padding-left: 3rem !important;
	}
}
@media (min-width: 1200px) {
	.d-xl-inline {
		display: inline !important;
	}
	.d-xl-inline-block {
		display: inline-block !important;
	}
	.d-xl-block {
		display: block !important;
	}
	.d-xl-grid {
		display: grid !important;
	}
	.d-xl-table {
		display: table !important;
	}
	.d-xl-table-row {
		display: table-row !important;
	}
	.d-xl-table-cell {
		display: table-cell !important;
	}
	.d-xl-flex {
		display: flex !important;
	}
	.d-xl-inline-flex {
		display: inline-flex !important;
	}
	.d-xl-none {
		display: none !important;
	}
	.flex-xl-fill {
		flex: 1 1 auto !important;
	}
	.flex-xl-row {
		flex-direction: row !important;
	}
	.flex-xl-column {
		flex-direction: column !important;
	}
	.flex-xl-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-xl-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-xl-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-xl-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-xl-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-xl-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-xl-wrap {
		flex-wrap: wrap !important;
	}
	.flex-xl-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-xl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.justify-content-xl-start {
		justify-content: flex-start !important;
	}
	.justify-content-xl-end {
		justify-content: flex-end !important;
	}
	.justify-content-xl-center {
		justify-content: center !important;
	}
	.justify-content-xl-between {
		justify-content: space-between !important;
	}
	.justify-content-xl-around {
		justify-content: space-around !important;
	}
	.justify-content-xl-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-xl-start {
		align-items: flex-start !important;
	}
	.align-items-xl-end {
		align-items: flex-end !important;
	}
	.align-items-xl-center {
		align-items: center !important;
	}
	.align-items-xl-baseline {
		align-items: baseline !important;
	}
	.align-items-xl-stretch {
		align-items: stretch !important;
	}
	.align-content-xl-start {
		align-content: flex-start !important;
	}
	.align-content-xl-end {
		align-content: flex-end !important;
	}
	.align-content-xl-center {
		align-content: center !important;
	}
	.align-content-xl-between {
		align-content: space-between !important;
	}
	.align-content-xl-around {
		align-content: space-around !important;
	}
	.align-content-xl-stretch {
		align-content: stretch !important;
	}
	.align-self-xl-auto {
		align-self: auto !important;
	}
	.align-self-xl-start {
		align-self: flex-start !important;
	}
	.align-self-xl-end {
		align-self: flex-end !important;
	}
	.align-self-xl-center {
		align-self: center !important;
	}
	.align-self-xl-baseline {
		align-self: baseline !important;
	}
	.align-self-xl-stretch {
		align-self: stretch !important;
	}
	.order-xl-first {
		order: -1 !important;
	}
	.order-xl-0 {
		order: 0 !important;
	}
	.order-xl-1 {
		order: 1 !important;
	}
	.order-xl-2 {
		order: 2 !important;
	}
	.order-xl-3 {
		order: 3 !important;
	}
	.order-xl-4 {
		order: 4 !important;
	}
	.order-xl-5 {
		order: 5 !important;
	}
	.order-xl-last {
		order: 6 !important;
	}
	.m-xl-0 {
		margin: 0 !important;
	}
	.m-xl-1 {
		margin: 0.25rem !important;
	}
	.m-xl-2 {
		margin: 0.5rem !important;
	}
	.m-xl-3 {
		margin: 1rem !important;
	}
	.m-xl-4 {
		margin: 1.5rem !important;
	}
	.m-xl-5 {
		margin: 3rem !important;
	}
	.m-xl-auto {
		margin: auto !important;
	}
	.mx-xl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-xl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-xl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-xl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-xl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-xl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-xl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-xl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-xl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-xl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-xl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-xl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-xl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-xl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-xl-0 {
		margin-top: 0 !important;
	}
	.mt-xl-1 {
		margin-top: 0.25rem !important;
	}
	.mt-xl-2 {
		margin-top: 0.5rem !important;
	}
	.mt-xl-3 {
		margin-top: 1rem !important;
	}
	.mt-xl-4 {
		margin-top: 1.5rem !important;
	}
	.mt-xl-5 {
		margin-top: 3rem !important;
	}
	.mt-xl-auto {
		margin-top: auto !important;
	}
	.me-xl-0 {
		margin-right: 0 !important;
	}
	.me-xl-1 {
		margin-right: 0.25rem !important;
	}
	.me-xl-2 {
		margin-right: 0.5rem !important;
	}
	.me-xl-3 {
		margin-right: 1rem !important;
	}
	.me-xl-4 {
		margin-right: 1.5rem !important;
	}
	.me-xl-5 {
		margin-right: 3rem !important;
	}
	.me-xl-auto {
		margin-right: auto !important;
	}
	.mb-xl-0 {
		margin-bottom: 0 !important;
	}
	.mb-xl-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-xl-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-xl-3 {
		margin-bottom: 1rem !important;
	}
	.mb-xl-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-xl-5 {
		margin-bottom: 3rem !important;
	}
	.mb-xl-auto {
		margin-bottom: auto !important;
	}
	.ms-xl-0 {
		margin-left: 0 !important;
	}
	.ms-xl-1 {
		margin-left: 0.25rem !important;
	}
	.ms-xl-2 {
		margin-left: 0.5rem !important;
	}
	.ms-xl-3 {
		margin-left: 1rem !important;
	}
	.ms-xl-4 {
		margin-left: 1.5rem !important;
	}
	.ms-xl-5 {
		margin-left: 3rem !important;
	}
	.ms-xl-auto {
		margin-left: auto !important;
	}
	.p-xl-0 {
		padding: 0 !important;
	}
	.p-xl-1 {
		padding: 0.25rem !important;
	}
	.p-xl-2 {
		padding: 0.5rem !important;
	}
	.p-xl-3 {
		padding: 1rem !important;
	}
	.p-xl-4 {
		padding: 1.5rem !important;
	}
	.p-xl-5 {
		padding: 3rem !important;
	}
	.px-xl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-xl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-xl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-xl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-xl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-xl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-xl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-xl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-xl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-xl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-xl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-xl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-xl-0 {
		padding-top: 0 !important;
	}
	.pt-xl-1 {
		padding-top: 0.25rem !important;
	}
	.pt-xl-2 {
		padding-top: 0.5rem !important;
	}
	.pt-xl-3 {
		padding-top: 1rem !important;
	}
	.pt-xl-4 {
		padding-top: 1.5rem !important;
	}
	.pt-xl-5 {
		padding-top: 3rem !important;
	}
	.pe-xl-0 {
		padding-right: 0 !important;
	}
	.pe-xl-1 {
		padding-right: 0.25rem !important;
	}
	.pe-xl-2 {
		padding-right: 0.5rem !important;
	}
	.pe-xl-3 {
		padding-right: 1rem !important;
	}
	.pe-xl-4 {
		padding-right: 1.5rem !important;
	}
	.pe-xl-5 {
		padding-right: 3rem !important;
	}
	.pb-xl-0 {
		padding-bottom: 0 !important;
	}
	.pb-xl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-xl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-xl-3 {
		padding-bottom: 1rem !important;
	}
	.pb-xl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-xl-5 {
		padding-bottom: 3rem !important;
	}
	.ps-xl-0 {
		padding-left: 0 !important;
	}
	.ps-xl-1 {
		padding-left: 0.25rem !important;
	}
	.ps-xl-2 {
		padding-left: 0.5rem !important;
	}
	.ps-xl-3 {
		padding-left: 1rem !important;
	}
	.ps-xl-4 {
		padding-left: 1.5rem !important;
	}
	.ps-xl-5 {
		padding-left: 3rem !important;
	}
}
@media (min-width: 1400px) {
	.d-xxl-inline {
		display: inline !important;
	}
	.d-xxl-inline-block {
		display: inline-block !important;
	}
	.d-xxl-block {
		display: block !important;
	}
	.d-xxl-grid {
		display: grid !important;
	}
	.d-xxl-table {
		display: table !important;
	}
	.d-xxl-table-row {
		display: table-row !important;
	}
	.d-xxl-table-cell {
		display: table-cell !important;
	}
	.d-xxl-flex {
		display: flex !important;
	}
	.d-xxl-inline-flex {
		display: inline-flex !important;
	}
	.d-xxl-none {
		display: none !important;
	}
	.flex-xxl-fill {
		flex: 1 1 auto !important;
	}
	.flex-xxl-row {
		flex-direction: row !important;
	}
	.flex-xxl-column {
		flex-direction: column !important;
	}
	.flex-xxl-row-reverse {
		flex-direction: row-reverse !important;
	}
	.flex-xxl-column-reverse {
		flex-direction: column-reverse !important;
	}
	.flex-xxl-grow-0 {
		flex-grow: 0 !important;
	}
	.flex-xxl-grow-1 {
		flex-grow: 1 !important;
	}
	.flex-xxl-shrink-0 {
		flex-shrink: 0 !important;
	}
	.flex-xxl-shrink-1 {
		flex-shrink: 1 !important;
	}
	.flex-xxl-wrap {
		flex-wrap: wrap !important;
	}
	.flex-xxl-nowrap {
		flex-wrap: nowrap !important;
	}
	.flex-xxl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}
	.justify-content-xxl-start {
		justify-content: flex-start !important;
	}
	.justify-content-xxl-end {
		justify-content: flex-end !important;
	}
	.justify-content-xxl-center {
		justify-content: center !important;
	}
	.justify-content-xxl-between {
		justify-content: space-between !important;
	}
	.justify-content-xxl-around {
		justify-content: space-around !important;
	}
	.justify-content-xxl-evenly {
		justify-content: space-evenly !important;
	}
	.align-items-xxl-start {
		align-items: flex-start !important;
	}
	.align-items-xxl-end {
		align-items: flex-end !important;
	}
	.align-items-xxl-center {
		align-items: center !important;
	}
	.align-items-xxl-baseline {
		align-items: baseline !important;
	}
	.align-items-xxl-stretch {
		align-items: stretch !important;
	}
	.align-content-xxl-start {
		align-content: flex-start !important;
	}
	.align-content-xxl-end {
		align-content: flex-end !important;
	}
	.align-content-xxl-center {
		align-content: center !important;
	}
	.align-content-xxl-between {
		align-content: space-between !important;
	}
	.align-content-xxl-around {
		align-content: space-around !important;
	}
	.align-content-xxl-stretch {
		align-content: stretch !important;
	}
	.align-self-xxl-auto {
		align-self: auto !important;
	}
	.align-self-xxl-start {
		align-self: flex-start !important;
	}
	.align-self-xxl-end {
		align-self: flex-end !important;
	}
	.align-self-xxl-center {
		align-self: center !important;
	}
	.align-self-xxl-baseline {
		align-self: baseline !important;
	}
	.align-self-xxl-stretch {
		align-self: stretch !important;
	}
	.order-xxl-first {
		order: -1 !important;
	}
	.order-xxl-0 {
		order: 0 !important;
	}
	.order-xxl-1 {
		order: 1 !important;
	}
	.order-xxl-2 {
		order: 2 !important;
	}
	.order-xxl-3 {
		order: 3 !important;
	}
	.order-xxl-4 {
		order: 4 !important;
	}
	.order-xxl-5 {
		order: 5 !important;
	}
	.order-xxl-last {
		order: 6 !important;
	}
	.m-xxl-0 {
		margin: 0 !important;
	}
	.m-xxl-1 {
		margin: 0.25rem !important;
	}
	.m-xxl-2 {
		margin: 0.5rem !important;
	}
	.m-xxl-3 {
		margin: 1rem !important;
	}
	.m-xxl-4 {
		margin: 1.5rem !important;
	}
	.m-xxl-5 {
		margin: 3rem !important;
	}
	.m-xxl-auto {
		margin: auto !important;
	}
	.mx-xxl-0 {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.mx-xxl-1 {
		margin-right: 0.25rem !important;
		margin-left: 0.25rem !important;
	}
	.mx-xxl-2 {
		margin-right: 0.5rem !important;
		margin-left: 0.5rem !important;
	}
	.mx-xxl-3 {
		margin-right: 1rem !important;
		margin-left: 1rem !important;
	}
	.mx-xxl-4 {
		margin-right: 1.5rem !important;
		margin-left: 1.5rem !important;
	}
	.mx-xxl-5 {
		margin-right: 3rem !important;
		margin-left: 3rem !important;
	}
	.mx-xxl-auto {
		margin-right: auto !important;
		margin-left: auto !important;
	}
	.my-xxl-0 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.my-xxl-1 {
		margin-top: 0.25rem !important;
		margin-bottom: 0.25rem !important;
	}
	.my-xxl-2 {
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	.my-xxl-3 {
		margin-top: 1rem !important;
		margin-bottom: 1rem !important;
	}
	.my-xxl-4 {
		margin-top: 1.5rem !important;
		margin-bottom: 1.5rem !important;
	}
	.my-xxl-5 {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
	}
	.my-xxl-auto {
		margin-top: auto !important;
		margin-bottom: auto !important;
	}
	.mt-xxl-0 {
		margin-top: 0 !important;
	}
	.mt-xxl-1 {
		margin-top: 0.25rem !important;
	}
	.mt-xxl-2 {
		margin-top: 0.5rem !important;
	}
	.mt-xxl-3 {
		margin-top: 1rem !important;
	}
	.mt-xxl-4 {
		margin-top: 1.5rem !important;
	}
	.mt-xxl-5 {
		margin-top: 3rem !important;
	}
	.mt-xxl-auto {
		margin-top: auto !important;
	}
	.me-xxl-0 {
		margin-right: 0 !important;
	}
	.me-xxl-1 {
		margin-right: 0.25rem !important;
	}
	.me-xxl-2 {
		margin-right: 0.5rem !important;
	}
	.me-xxl-3 {
		margin-right: 1rem !important;
	}
	.me-xxl-4 {
		margin-right: 1.5rem !important;
	}
	.me-xxl-5 {
		margin-right: 3rem !important;
	}
	.me-xxl-auto {
		margin-right: auto !important;
	}
	.mb-xxl-0 {
		margin-bottom: 0 !important;
	}
	.mb-xxl-1 {
		margin-bottom: 0.25rem !important;
	}
	.mb-xxl-2 {
		margin-bottom: 0.5rem !important;
	}
	.mb-xxl-3 {
		margin-bottom: 1rem !important;
	}
	.mb-xxl-4 {
		margin-bottom: 1.5rem !important;
	}
	.mb-xxl-5 {
		margin-bottom: 3rem !important;
	}
	.mb-xxl-auto {
		margin-bottom: auto !important;
	}
	.ms-xxl-0 {
		margin-left: 0 !important;
	}
	.ms-xxl-1 {
		margin-left: 0.25rem !important;
	}
	.ms-xxl-2 {
		margin-left: 0.5rem !important;
	}
	.ms-xxl-3 {
		margin-left: 1rem !important;
	}
	.ms-xxl-4 {
		margin-left: 1.5rem !important;
	}
	.ms-xxl-5 {
		margin-left: 3rem !important;
	}
	.ms-xxl-auto {
		margin-left: auto !important;
	}
	.p-xxl-0 {
		padding: 0 !important;
	}
	.p-xxl-1 {
		padding: 0.25rem !important;
	}
	.p-xxl-2 {
		padding: 0.5rem !important;
	}
	.p-xxl-3 {
		padding: 1rem !important;
	}
	.p-xxl-4 {
		padding: 1.5rem !important;
	}
	.p-xxl-5 {
		padding: 3rem !important;
	}
	.px-xxl-0 {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.px-xxl-1 {
		padding-right: 0.25rem !important;
		padding-left: 0.25rem !important;
	}
	.px-xxl-2 {
		padding-right: 0.5rem !important;
		padding-left: 0.5rem !important;
	}
	.px-xxl-3 {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.px-xxl-4 {
		padding-right: 1.5rem !important;
		padding-left: 1.5rem !important;
	}
	.px-xxl-5 {
		padding-right: 3rem !important;
		padding-left: 3rem !important;
	}
	.py-xxl-0 {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	.py-xxl-1 {
		padding-top: 0.25rem !important;
		padding-bottom: 0.25rem !important;
	}
	.py-xxl-2 {
		padding-top: 0.5rem !important;
		padding-bottom: 0.5rem !important;
	}
	.py-xxl-3 {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.py-xxl-4 {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.py-xxl-5 {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
	}
	.pt-xxl-0 {
		padding-top: 0 !important;
	}
	.pt-xxl-1 {
		padding-top: 0.25rem !important;
	}
	.pt-xxl-2 {
		padding-top: 0.5rem !important;
	}
	.pt-xxl-3 {
		padding-top: 1rem !important;
	}
	.pt-xxl-4 {
		padding-top: 1.5rem !important;
	}
	.pt-xxl-5 {
		padding-top: 3rem !important;
	}
	.pe-xxl-0 {
		padding-right: 0 !important;
	}
	.pe-xxl-1 {
		padding-right: 0.25rem !important;
	}
	.pe-xxl-2 {
		padding-right: 0.5rem !important;
	}
	.pe-xxl-3 {
		padding-right: 1rem !important;
	}
	.pe-xxl-4 {
		padding-right: 1.5rem !important;
	}
	.pe-xxl-5 {
		padding-right: 3rem !important;
	}
	.pb-xxl-0 {
		padding-bottom: 0 !important;
	}
	.pb-xxl-1 {
		padding-bottom: 0.25rem !important;
	}
	.pb-xxl-2 {
		padding-bottom: 0.5rem !important;
	}
	.pb-xxl-3 {
		padding-bottom: 1rem !important;
	}
	.pb-xxl-4 {
		padding-bottom: 1.5rem !important;
	}
	.pb-xxl-5 {
		padding-bottom: 3rem !important;
	}
	.ps-xxl-0 {
		padding-left: 0 !important;
	}
	.ps-xxl-1 {
		padding-left: 0.25rem !important;
	}
	.ps-xxl-2 {
		padding-left: 0.5rem !important;
	}
	.ps-xxl-3 {
		padding-left: 1rem !important;
	}
	.ps-xxl-4 {
		padding-left: 1.5rem !important;
	}
	.ps-xxl-5 {
		padding-left: 3rem !important;
	}
}
@media print {
	.d-print-inline {
		display: inline !important;
	}
	.d-print-inline-block {
		display: inline-block !important;
	}
	.d-print-block {
		display: block !important;
	}
	.d-print-grid {
		display: grid !important;
	}
	.d-print-table {
		display: table !important;
	}
	.d-print-table-row {
		display: table-row !important;
	}
	.d-print-table-cell {
		display: table-cell !important;
	}
	.d-print-flex {
		display: flex !important;
	}
	.d-print-inline-flex {
		display: inline-flex !important;
	}
	.d-print-none {
		display: none !important;
	}
}
